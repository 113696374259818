.Comment {
  margin-top: 10px;
  padding-top: 15px;
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 40px;
  background-color: white;
  box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);
  border-radius: 12px;

  &.repliesVisible {
    position: relative;
    padding-left: 36px;
    padding-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 22px;
      left: 19px;
      bottom: 63px;
      width: 1px;
      background-color: #c1c2d0;
    }
  }

  &.nested {
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 18px;
    box-shadow: none;
    border-radius: 0;

    &.firstOne {
      padding-top: 36px;
    }

    .textContent {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: calc(100% - 10px);
        left: -35px;
        width: 23px;
        height: 23px;
        border-bottom: 1px solid #c1c2d0;
        border-left: 1px solid #c1c2d0;
        border-bottom-left-radius: 10px;
      }
    }
  }

  &.imageTag {
    .textContent {
      cursor: pointer;
    }
  }

  .commentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .createdAt {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #7579ff;
    }

    .userBlock {
      display: flex;
      align-items: center;
      .userPic {
        display: flex;
        justify-content: center;
        width: 24px;
        height: 24px;
        align-items: center;
        font-weight: 700;
        font-size: 12.3871px;
        line-height: 19px;
        color: #7579ff;
        background-color: #edeeff;
        border-radius: 50%;
      }
      h1 {
        margin: 0;
        margin-left: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .textContent {
    margin-bottom: 12px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    .timeMark {
      display: inline-block;
      margin-right: 4px;
      font-size: 12px;
      color: #7579ff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .footer {
    display: flex;

    .likes {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #5f6073;

      svg {
        margin-right: 4px;
        cursor: pointer;
      }
    }

    .replies {
      display: flex;
      align-items: center;
      margin-right: 18px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #5f6073;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }
    }

    .replyButton {
      position: relative;
      top: 2px;
      display: flex;
      align-items: center;
      width: 29px;
      height: 15px;
      padding: 0;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      color: #5f6073;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .replyBoxContainer {
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .Comment {
    padding-right: 20px;
  }
}
