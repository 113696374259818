.Player {
  position: relative;
  width: 100%;
  height: 100%;

  video {
    background-color: black;
  }

  .controls {
    position: absolute;
    z-index: 10000;
    bottom: 0;
    left: 0;
    right: 0;
    height: 44px;
    background-color: rgba(27, 28, 55, 0.4);

    .button {
      position: absolute;
      top: 8px;
      cursor: pointer;

      &.playButton {
        left: 30px;
      }

      &.volumeButton {
        top: 8.5px;
        left: 69px;
      }

      &.fullscreenButton {
        top: 10px;
        right: 20px;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }

    .progressBar {
      position: absolute;
      top: -4px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 4px;
      background-color: white;

      .tags {
        display: flex;
        width: 100%;
        position: absolute;
        top: calc(-100% - 21px);
      }

      .progress {
        width: 0;
        height: 4px;
        background-color: #7579ff;
      }

      .handle {
        position: absolute;
        z-index: 1;
        left: 0;
        width: 12px;
        height: 12px;
        background-color: #edeeff;
        border: 2px solid #7579ff;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .timeSelector {
      display: flex;
      .mark {
        position: relative;
        z-index: 1;
        width: 12px;
        height: 12px;
        background-color: yellow;
        border-radius: 50%;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: calc(50% - 2px);
          left: 100%;
          display: block;
          width: var(--selectionLength);
          height: 4px;
          background-color: yellow;
        }
      }

      .markLeft,
      .markRight {
        position: absolute;
        z-index: 0;
        top: -2px;
        width: 3px;
        height: 16px;
        background-color: lightgray;
        cursor: e-resize;
      }
    }

    .tagContainer {
      display: flex;

      .mark {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 12px;
        height: 12px;
        background-color: greenyellow;
        border-radius: 50%;

        &.tagLeftMark {
          &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            left: 100%;
            display: block;
            width: var(--tagLength);
            height: 2px;
            background-color: white;
          }
        }
      }
    }
  }
}
