.Select {
  display: block;

  .label {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .selector {
    display: flex;
    align-items: center;
    width: 450px;
    height: 40px;
    padding-left: 30px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #5f6073;
    background-color: #edeeff;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9201 0.949997L10.4001 7.47C9.63008 8.24 8.37008 8.24 7.60008 7.47L1.08008 0.949997' stroke='%235F6073' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 34px;
    border-radius: 4px;
  }

  .options {
    position: absolute;
    z-index: 1;
    width: 450px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
      rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    border-radius: 4px;

    .option {
      height: 20px;
      padding-left: 30px;
      font-size: 12px;

      &:hover {
        background-color: gainsboro;
        cursor: pointer;
      }
    }
  }
}
