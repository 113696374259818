.SignUp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fcfcff;
  background-image: url(../../assets/images/loginBackground2.svg),
    url(../../assets/images/loginBackground.svg),
    url(../../assets/images/logo.png);
  background-repeat: no-repeat;
  background-position: top right, bottom left, left 60px top 60px;
  background-size: auto, auto, 210px;

  .container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 452px;
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: white;
    box-shadow: -1px 5px 63px rgba(0, 0, 0, 0.13);
    border-radius: 30px;

    form {
      display: flex;
      flex-direction: column;
      width: 352px;

      .link {
        display: inline-block;
        width: 100%;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #5f6073;
        text-align: center;

        a {
          font-weight: bold;
          font-size: 12px;
          color: #7a74fb;
        }
      }

      .error {
        padding-top: 5px;
        font-size: 12px;
        color: red;
      }

      .submitButton {
        width: 100%;
        height: 48px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: white;
        background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }

      .selectContainer {
        margin-bottom: 24px;
      }

      .inputContainer {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .header {
      width: 352px;
      margin-bottom: 25px;

      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #1b1c37;
      }

      .steps {
        margin-top: 8px;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #5f6073;

        span {
          &.active {
            color: #7579ff;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .SignUp {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 170px;
    background-image: url(../../assets/images/loginBackgroundMobile.svg),
      url(../../assets/images/mobileLogo.svg);
    background-position: bottom center, center 60px;
    background-size: contain, 120px;
    .container {
      width: 384px;
      padding-top: 42px;
      padding-bottom: 85px;
      form {
        width: 299px;

        .submitButton {
          height: 40px;
          font-weight: 600;
          font-size: 13.5929px;
          line-height: 20px;
        }

        .selectContainer {
          margin-bottom: 20px;
        }

        .inputContainer {
          margin-bottom: 20px;
        }
      }

      .header {
        width: 299px;
        margin-bottom: 21px;
        h1 {
          font-size: 20.3894px;
          line-height: 31px;
        }
      }
    }
  }
}
