.Layout {
  display: flex;

  .rightColumn {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .leftColumn {
    position: fixed;
    z-index: 223;
    width: 211px;
    overflow-y: scroll;
    max-height: 100%;
    transition: all 0.1s;
  }
}
