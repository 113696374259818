.TextInput {
  display: block;
  width: 499px;
  position: relative;
  .label {
    display: block;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .maxCharacters {
    position: absolute;
    top: 65px;
    right: 30px;
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    color: #5f6073;
  }

  input {
    width: 499px;
    height: 40px;
    padding-left: 30px;
    padding-right: 176px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    background-color: #edeeff;
    border-radius: 4px;
    border: none;
    &::placeholder {
      color: #5f6073;
    }
    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 1024px) {
  .TextInput {
    .maxCharacters {
      display: none;
    }

    input {
      padding-right: 30px;
    }
  }
}
