.TextInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    .required {
      color: #7a74fb;
    }
  }

  .inputContainer {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 12px;
      left: 20px;
      display: block;
      width: 24px;
      height: 24px;
    }

    &.none {
      &::after {
        display: none;
      }
      input {
        padding-left: 20px;
      }
    }

    &.email {
      &::after {
        background-image: url(../../../assets/images/auth-form/email.svg);
      }
    }

    &.password {
      &::after {
        background-image: url(../../../assets/images/auth-form/password.svg);
      }
    }

    &.name {
      &::after {
        background-image: url(../../../assets/images/auth-form/name.svg);
      }
    }

    &.company {
      &::after {
        background-image: url(../../../assets/images/auth-form/company.svg);
      }
    }

    input {
      width: 100%;
      height: 48px;
      padding-left: 64px;
      padding-right: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 13.5929px;
      line-height: 20px;
      border: 1px solid #1b1c37;
      border-radius: 10px;

      &::placeholder {
        color: #5f6073;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .TextInput {
    .label {
      margin-bottom: 10px;
      font-size: 13.5929px;
      line-height: 20px;
    }

    input {
      height: 40px;
      padding-left: 54px;
      background-position: 17px center;
      background-size: 20px;
    }
  }
}
