.Comments {
  padding-bottom: 21px;
  padding-right: 10px;

  .header {
    background-color: white;

    .buttons {
      display: flex;
      justify-content: space-between;
      .commentsButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 153px;
        height: 19px;
        border-radius: 62px;
        border: 1px solid #7579ff;
        cursor: pointer;

        &.active {
          background-color: #7579ff;

          span {
            color: white;
          }
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #7579ff;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Comments {
    min-height: calc(100vh - 121px - var(--parentHeight));
    padding-bottom: 21px;
  }
}
