.ReplyBox {
  position: relative;
  min-height: 130px;
  margin-bottom: 10px;
  box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);
  border-radius: 10px;

  &.isNested {
    padding-bottom: 10px;
  }

  .editorComponent {
    position: relative;
    min-height: 94px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;

    .toolbarIcon {
      margin: 0;
      padding: 0;
      margin-right: 5px;
      background-color: transparent;
      border: none;
    }

    .placeholder {
      position: absolute;
      top: 54px;
      left: 20px;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #c1c2d0;
      pointer-events: none;
      user-select: none;
    }

    .wrapper {
      background-color: white;
    }

    .toolbar {
      position: absolute;
      z-index: 22;
      top: 0;
      left: -1px;
      right: -1px;
      height: 48px;
      margin: 0;
      padding: 0;
      padding-top: 16px;
      padding-left: 12px;
      background-color: white;
      border-radius: 10px;
      border-bottom: none;
    }

    .editor {
      max-width: 381px;
      min-height: 94px;
      padding-top: 42px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  }

  .buttonContainer {
    padding-left: 20px;
    padding-bottom: 10px;

    .tagsButton {
      // width: 111px;
      height: 25px;
      padding-left: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #1b1c37;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99998 1.83325C4.31998 1.83325 1.33331 4.81992 1.33331 8.49992C1.33331 12.1799 4.31998 15.1666 7.99998 15.1666C11.68 15.1666 14.6666 12.1799 14.6666 8.49992C14.6666 4.81992 11.68 1.83325 7.99998 1.83325ZM7.99998 10.1666C7.07998 10.1666 6.33331 9.41992 6.33331 8.49992C6.33331 7.57992 7.07998 6.83325 7.99998 6.83325C8.91998 6.83325 9.66665 7.57992 9.66665 8.49992C9.66665 9.41992 8.91998 10.1666 7.99998 10.1666Z' fill='%23292D32'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 6px center;
      background-color: #edeeff;
      border-radius: 6px;
      border: none;

      &.taggingMode {
        width: auto;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99986 1.8335C4.32652 1.8335 1.33319 4.82683 1.33319 8.50016C1.33319 12.1735 4.32652 15.1668 7.99986 15.1668C11.6732 15.1668 14.6665 12.1735 14.6665 8.50016C14.6665 4.82683 11.6732 1.8335 7.99986 1.8335ZM10.8999 10.8802C10.8065 11.0402 10.6399 11.1268 10.4665 11.1268C10.3799 11.1268 10.2932 11.1068 10.2132 11.0535L8.14652 9.82016C7.63319 9.5135 7.25319 8.84016 7.25319 8.24683V5.5135C7.25319 5.24016 7.47986 5.0135 7.75319 5.0135C8.02652 5.0135 8.25319 5.24016 8.25319 5.5135V8.24683C8.25319 8.48683 8.45319 8.84016 8.65986 8.96016L10.7265 10.1935C10.9665 10.3335 11.0465 10.6402 10.8999 10.8802Z' fill='%231B1C37'/%3E%3C/svg%3E%0A");
        .time {
          margin: 0;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #1b1c37;
          }
        }
      }
    }
  }
}
