.Dashboard {
  position: relative;
  display: flex;
  min-height: 100vh;

  .container {
    flex: 1;

    .noBriefs {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 46px;

      h1 {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 48px;
        line-height: 72px;
        color: #1b1c37;
      }

      p {
        position: relative;
        width: 402px;
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #1b1c37;

        .arrow {
          position: absolute;
          bottom: 11px;
          display: block;
          width: 100%;
          height: 158px;
          border-bottom: 2px dashed #1b1c37;
          border-right: 2px dashed #1b1c37;

          &::after {
            content: '';
            position: absolute;
            top: -1px;
            right: -3px;
            display: block;
            width: 11px;
            height: 1.5px;
            background-color: black;
            border-radius: 4px;
            transform: rotate(-50deg);
          }

          &::before {
            content: '';
            position: absolute;
            top: -1px;
            right: -10px;
            display: block;
            width: 11px;
            height: 1.5px;
            background-color: black;
            border-radius: 4px;
            transform: rotate(50deg);
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 7px;
          right: 0px;
          display: block;
          width: 10px;
          height: 10px;
          background-color: black;
          border-radius: 50%;
        }
      }
    }

    .cardsContainer {
      position: absolute;
      left: 0;
      right: 298px;
      padding-top: 40px;
      padding-bottom: 30px;
      transition: right 0.2s;

      &.collapsed {
        right: 40px;
      }

      .videos {
        h2 {
          margin-top: 13px;
        }
      }

      h2 {
        margin: 0;
        margin-bottom: 3px;
        padding-left: 60px;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #1b1c37;
      }

      .cards {
        position: relative;
        height: 340px;

        .container {
          position: absolute;
          top: 0;
          left: 30px;
          // right: 145px;
          bottom: 0;
          display: flex;
          align-items: center;
          padding-left: 30px;
          padding-right: 15px;
          overflow: hidden;

          .scrollable {
            position: relative;
            left: 0;
            display: flex;
            gap: 50px;
            transition: all 0.2s;
          }
        }

        .scrollButton {
          position: absolute;
          z-index: 22;
          top: calc(50% - 10px);
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          background-color: transparent;
          border: 1.5px solid #5f6073;
          border-radius: 50%;
          cursor: pointer;
        }

        .scrollRight {
          right: 20px;
        }

        .scrollLeft {
          left: 10px;
          transform: scaleX(-1);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Dashboard {
    .container {
      .cardsContainer {
        right: 0;

        .cards {
          .container {
            left: 50%;
            transform: translateX(-50%);
          }

          .scrollRight {
            right: 10px;
          }
        }
      }
    }
  }
}
