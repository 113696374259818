.Card {
  min-width: 260px;
  max-width: 260px;
  height: 287px;
  box-shadow: -1px 5px 21px rgba(0, 0, 0, 0.13);
  border-radius: 22px;
  cursor: pointer;
  overflow: hidden;

  header {
    position: relative;
    height: 144px;
    padding-top: 25px;
    background-image: url(../../../assets/images/cardInProgress.svg);
    background-size: 132px 73px;
    background-repeat: no-repeat;
    background-position: center 51px;
    background-color: #edeeff;
    box-shadow: 2px 4px 8px rgba(117, 121, 255, 0.18);

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 144px;
      object-fit: cover;
    }

    .statusTitle {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5f6073;
      text-align: center;
      text-transform: capitalize;
    }

    .status {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-color: #ff7f7f;
      border: 2px solid white;
      border-radius: 50%;

      &.brief {
        background-color: #5f6073;
      }

      &.revisions {
        background-color: #ffda91;
      }

      &.production {
        background-color: #91d1ff;
      }

      &.completed {
        background-color: #91ffb6;
      }

      &.editrequests {
        background-color: #ff7f7f;
      }

      &.approved {
        background-color: #ffbdcd;
      }
    }
  }

  .content {
    position: relative;
    margin-bottom: 10px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .time {
      display: block;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 9px;
      color: #5f6073;
    }
    .description {
      width: 198px;
      height: 12px;
      margin: 0;
      margin-bottom: 5px;
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #5f6073;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .cardType {
      margin: 0;
      margin-right: 5px;
      padding-top: 3px;
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 3px;
      font-weight: 500;
      font-size: 10px;
      color: white;
      background: #7579ff;
      border-radius: 18px;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 17px;
    padding-bottom: 16px;

    .comments {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #5f6073;

      svg {
        margin-right: 4px;
      }
    }

    .platforms {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
