.filler {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 211px;
  background-color: #edeeff;
  transition: all 0.1s;

  &.collapsed {
    width: 84px;
  }
}

.Sidebar {
  position: relative;
  left: 0;
  width: 211px;
  min-height: 100vh;
  padding-left: 30px;
  padding-right: 20px;
  background-color: #edeeff;
  overflow: hidden;
  transition: all 0.1s;

  &.collapsed {
    width: 84px;

    .logo {
      left: 17px;
    }

    .userBlock {
      .userPic {
        position: relative;
        left: -7px;
      }

      .username {
        font-size: 0;
      }

      .companyName {
        font-size: 0;

        &::after {
          right: -12px;
        }
      }
    }

    nav {
      h2 {
        position: relative;
        left: -5px;
      }
      ul {
        li {
          span {
            font-size: 0;
            background-position: 10px center;

            &.activeLink {
              width: 42px;
            }
          }
        }
      }
    }
  }

  .logo {
    position: absolute;
    z-index: 22;
    top: 25px;
    left: 24px;
    width: 50px;
  }

  .userBlock {
    position: relative;
    left: -5px;
    display: flex;
    align-items: center;
    gap: 7px;
    padding-top: 100px;
    padding-bottom: 42px;
    border-bottom: 0.5px solid #c1c2d0;

    a {
      text-decoration: none;
    }
    .userPic {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 46px;
      background-color: transparent;
      border: 2px solid #7579ff;
      border-radius: 50%;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #7579ff;
      }
    }

    .username {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
    }

    .companyName {
      position: relative;
      margin: 0;
      font-weight: 300;
      font-size: 10px;
      line-height: 24px;
      white-space: nowrap;
    }
  }

  nav {
    margin-top: 25px;
    padding-bottom: 19px;
    border-bottom: 0.5px solid #c1c2d0;

    &:last-child {
      border-bottom: none;
    }
    h2 {
      margin: 0;
      margin-bottom: 18px;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        span {
          position: relative;
          left: -11px;
          display: inline-flex;
          align-items: center;
          height: 40px;
          background-repeat: no-repeat;
          background-position: 11px center;
          padding-left: 45px;
          border-radius: 8px;

          &.dashboard {
            background-image: url(../../../assets/images/sidebar/dashboard.svg);
          }

          &.production {
            background-image: url(../../../assets/images/sidebar/production.svg);
          }

          &.designReview {
            background-image: url(../../../assets/images/sidebar/designReview.svg);
          }

          &.approved {
            background-image: url(../../../assets/images/sidebar/approved.svg);
          }

          &.review {
            background-image: url(../../../assets/images/sidebar/readyforreview.svg);
          }

          &.editrequests {
            background-image: url(../../../assets/images/sidebar/revisions.svg);
          }

          &.finalLegal {
            background-image: url(../../../assets/images/sidebar/finalLegal.svg);
          }

          &.completed {
            background-image: url(../../../assets/images/sidebar/completed.svg);
          }

          &.facebook {
            background-image: url(../../../assets/images/sidebar/meta.svg);
          }

          &.instagram {
            background-image: url(../../../assets/images/sidebar/instagram.svg);
          }

          &.snapchat {
            background-image: url(../../../assets/images/sidebar/snapchat.svg);
          }

          &.tikTok {
            background-image: url(../../../assets/images/sidebar/tikTok.svg);
          }

          &.pinterest {
            background-image: url(../../../assets/images/sidebar/pinterest.svg);
          }

          &.youTube {
            background-image: url(../../../assets/images/sidebar/youTube.svg);
          }

          &.uac {
            background-image: url(../../../assets/images/sidebar/uac.svg);
          }

          &.activeLink {
            width: 151px;
            color: #edeeff;
            background-color: #7579ff;

            &.dashboard {
              background-image: url(../../../assets/images/sidebar/dashboardActive.svg);
            }

            &.approved {
              background-image: url(../../../assets/images/sidebar/approvedActive.svg);
            }

            &.editrequests {
              background-image: url(../../../assets/images/sidebar/revisionsActive.svg);
            }

            &.review {
              background-image: url(../../../assets/images/sidebar/readyforreviewActive.svg);
            }

            &.completed {
              background-image: url(../../../assets/images/sidebar/completedActive.svg);
            }

            &.designReview {
              background-image: url(../../../assets/images/sidebar/designReviewActive.svg);
            }

            &.revisions {
              background-image: url(../../../assets/images/sidebar/revisionsActive.svg);
            }

            &.production {
              background-image: url(../../../assets/images/sidebar/productionActive.svg);
            }

            &.youTube {
              background-image: url(../../../assets/images/sidebar/youTubeActive.svg);
            }

            &.tikTok {
              background-image: url(../../../assets/images/sidebar/tikTokActive.svg);
            }

            &.snapchat {
              background-image: url(../../../assets/images/sidebar/snapchatActive.svg);
            }

            &.facebook {
              background-image: url(../../../assets/images/sidebar/metaActive.svg);
            }

            &.instagram {
              background-image: url(../../../assets/images/sidebar/instagramActive.svg);
            }
          }
        }
        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #5f6073;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }

  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    .backdrop {
      background-color: rgba(255, 255, 255, 0.4);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }
}
