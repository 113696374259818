.MultiSelect {
  font-family: 'Poppins';
  font-size: 12px;
  .label {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .multiselectContainer {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      left: calc(var(--width) - 30px);
      width: 17px;
      height: 10px;
      // background-image: url(../../../assets/images/selectIcon.svg);
    }
  }

  .error {
    display: block;
    position: relative;
    top: -15px;
  }
}
