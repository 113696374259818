.Ticket {
  display: flex;

  &.tagging {
    .leftColumn {
      display: flex;
      flex-direction: row-reverse;

      .mediaView {
        display: block;
        width: auto;
        overflow: hidden;
        div {
          height: auto;
        }
      }

      .ticketInfo {
        max-width: 299px;
        margin-right: 15px;
        min-width: 286px;

        header {
          .statusContainer {
            display: none;
          }
        }

        .description {
          width: unset;
        }

        .statusSelector {
          padding-top: 25px;
          ul {
            padding: 0;
            list-style: none;
            li {
              display: flex;
              align-items: center;
              max-width: 246px;
              height: 50px;
              border-bottom: 1px solid #c1c2d0;
              cursor: pointer;

              &.active {
                transform: scaleX(1.1);
                position: relative;
                top: -1px;
                background-color: #7579ff;
                border-radius: 5px;
                .statusName {
                  position: relative;
                  left: 2px;
                  transform: scaleX(0.9);
                  color: white;
                }
                .statusIcon {
                  position: relative;
                  left: 10px;
                  transform: scaleX(0.91);
                  border: 3.5px solid white;
                }
              }

              &.readyforreview {
                .statusIcon {
                  background-color: #ffda91;
                }
              }

              &.brief {
                .statusIcon {
                  background-color: #5f6073;
                }
              }

              &.production {
                .statusIcon {
                  background-color: #91d1ff;
                }
              }

              &.completed {
                .statusIcon {
                  background-color: #91ffb6;
                }
              }

              &.editrequests {
                .statusIcon {
                  background-color: #ff7f7f;
                }
              }

              &.approved {
                .statusIcon {
                  background-color: #ffbdcd;
                }
              }

              .statusIcon {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                background-color: #91d1ff;
                border: 4px solid #5f6073;
                border-radius: 50%;
              }

              .statusName {
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  .leftColumn {
    position: relative;
    flex: 1;
    padding-top: 32px;
    padding-left: 90px;
    padding-right: 90px;

    .switchButtons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;

      &.slider {
        justify-content: center;
      }

      .viewModeSwitcher {
        display: flex;
        gap: 12px;
      }

      button {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5f6073;
        border: none;
        background-color: transparent;
        padding: 0;

        &.fileSwitchButton {
          width: 118px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.56995 5.92993L3.49995 11.9999L9.56995 18.0699' stroke='%235F6073' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.5 12H3.67' stroke='%235F6073' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          text-align: right;
          background-repeat: no-repeat;
          &.next {
            text-align: left;
            transform: scaleX(-1);
            span {
              display: block;
              transform: scaleX(-1);
            }
          }
        }
      }
    }

    .snapchatButton {
      display: none;
      position: absolute;
      z-index: 2;
      top: 43px;
      right: 80px;
    }

    .mediaView {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 982 / 488;
      margin-bottom: 32px;

      video {
        width: 100%;
        height: 100%;
      }

      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    header {
      position: relative;
      border-bottom: 1px solid #c1c2d0;
      h1 {
        margin: 0;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }

      .createdAt {
        display: block;
        margin-bottom: 18px;
        font-style: italic;
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
        color: #5f6073;
      }

      .statusContainer {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        gap: 3px;
        align-items: center;
        height: 34px;
        padding-left: 8px;
        padding-right: 8px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        cursor: pointer;

        .uploadButton {
          position: absolute;
          top: 0;
          left: -120px;
          height: 34px;
          padding-left: 16px;
          padding-right: 16px;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: white;
          background-color: #7579ff;
          border-radius: 8px;
          border: none;
        }

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: white;
          text-transform: capitalize;
        }
        .status {
          width: 22px;
          height: 22px;
          background-color: #91ffb6;
          border-radius: 50%;

          &.brief {
            background-color: #5f6073;
          }

          &.readyforreview {
            background-color: #ffda91;
          }

          &.production {
            background-color: #91d1ff;
          }

          &.completed {
            background-color: #91ffb6;
          }

          &.editrequests {
            background-color: #ff7f7f;
          }

          &.approved {
            background-color: #ffbdcd;
          }
        }
      }
    }

    .info {
      position: relative;
      border-bottom: 1px solid #c1c2d0;

      .priority {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }
      .authorInfo {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding-top: 25px;

        .userPic {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          font-weight: 700;
          font-size: 23.7419px;
          line-height: 36px;
          color: #7579ff;
          background-color: #edeeff;
          border-radius: 50%;
        }

        .authorName {
          display: flex;
          flex-direction: column;
          margin-left: 12px;

          .name {
            display: block;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #1b1c37;
          }
          .role {
            font-weight: 300;
            font-size: 10px;
            line-height: 15px;
            color: #5f6073;
          }
        }
      }

      .description {
        display: inline-block;
        width: 450px;
        padding-bottom: 25px;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #5f6073;
      }
    }
  }

  .rightColumn {
    width: 391px;
    padding-top: 12px;
  }
}

@media (max-width: 1024px) {
  .Ticket {
    display: block;

    &.tagging {
      .leftColumn {
        display: block;

        .ticketInfo {
          max-width: 100%;
          width: 100%;

          .statusSelector {
            ul {
              li {
                max-width: 100%;

                &.active {
                  .statusIcon {
                    left: 3.5vw;
                  }
                  .statusName {
                    left: 3vw;
                  }
                }
              }
            }
          }
        }
      }
    }

    .leftColumn {
      .info {
        border-bottom: none;

        .description {
          width: 100%;
        }
      }
    }

    .rightColumn {
      width: 100%;
    }
  }
}
