.ForgotPassword {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fcfcff;
  background-image: url(../../assets/images/loginBackground2.svg),
    url(../../assets/images/loginBackground.svg),
    url(../../assets/images/logo.png);
  background-repeat: no-repeat;
  background-position: top right, bottom left, left 60px top 60px;
  background-size: auto, auto, 210px;

  .container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 452px;
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: white;
    box-shadow: -1px 5px 63px rgba(0, 0, 0, 0.13);
    border-radius: 30px;

    h1 {
      width: 352px;
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #1b1c37;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 352px;

      .error {
        padding-top: 5px;
        font-size: 12px;
        color: red;
      }

      .inputContainer {
        width: 100%;
        margin-bottom: 25px;
      }
      .submitButton {
        width: 100%;
        height: 48px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: white;
        background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
}
