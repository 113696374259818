.ProfilePage {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  .content {
    flex: 1;
    .card {
      width: 857px;
      margin-top: 48px;
      margin-left: 38px;
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 105px;
      padding-bottom: 70px;
      box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);
      border-radius: 20px;

      .header {
        margin-bottom: 3px;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #1b1c37;

        p {
          cursor: pointer;
          svg {
            margin-right: 10px;
          }
        }
      }

      .container {
        display: flex;
        justify-content: space-between;
        padding-left: 60px;

        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 186px;
          height: 186px;
          margin-top: 20px;
          font-weight: 700;
          font-size: 96px;
          line-height: 144px;
          color: #7579ff;
          background-color: #edeeff;
          border-radius: 50%;
        }

        .userInfo {
          margin-top: 39px;
          h1 {
            margin-bottom: 1px;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
          }

          .email {
            display: block;
            margin-bottom: 24px;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            text-align: left;
            color: #5f6073;
          }

          h2 {
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
          }

          .companyName {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #5f6073;
            text-align: left;
          }
        }

        .buttons {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 226px;
          .slackButton {
            width: 58px;
            height: 58px;
            background-color: #f2f3fa;
            border-radius: 12px;
            border: none;

            .connectionStatus {
              position: absolute;
              right: -5px;
              width: 16px;
              height: 16px;
              background-color: #ff7f7f;
              border: 1px solid white;
              border-radius: 50%;

              &.active {
                background-color: #91ffb6;
              }
            }
          }
        }
      }
    }
  }
}
