.Editor {
  position: relative;
  min-height: 145px;
  margin-bottom: 5px;
  border-radius: 4px;

  .toolbarIcon {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  header {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 13px;
    padding-right: 30px;
    padding-bottom: 8px;
    background-color: #edeeff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .limit {
      font-style: italic;
      font-weight: 275;
      font-size: 10px;
      line-height: 15px;
      color: #5f6073;
      user-select: none;
    }
  }

  .placeholder {
    position: absolute;
    top: 44px;
    left: 20px;
    font-weight: 300;
    font-size: 13.5929px;
    line-height: 20px;
    color: #5f6073;
    pointer-events: none;
    user-select: none;
  }

  .wrapper {
    background-color: white;
    border: 1px solid #1b1c37;
    border-radius: 10px;
  }

  .toolbar {
    position: absolute;
    z-index: 22;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    margin: 0;
    padding: 0;
    padding-left: 13px;
    background-color: white;
    border: 1px solid #1b1c37;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }

  .editor {
    max-width: 352px;
    min-height: 145px;
    max-height: 145px;
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 1024px) {
  .Editor {
    max-width: 290px;

    .editor {
      max-width: 290px;
    }
  }
}
