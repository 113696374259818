.WorkspaceMenu {
  width: 352px;
  padding-top: 16.5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 11px;
  box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 11px;
  }

  header {
    position: relative;
    z-index: 3;
    width: 100%;
    padding-left: 44px;
    padding-bottom: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.21999 5.93974C2.21999 5.81307 2.26665 5.68641 2.36665 5.58641C2.55999 5.39307 2.87999 5.39307 3.07332 5.58641L7.41999 9.93307C7.73999 10.2531 8.25999 10.2531 8.57999 9.93307L12.9267 5.58641C13.12 5.39307 13.44 5.39307 13.6333 5.58641C13.8267 5.77974 13.8267 6.09974 13.6333 6.29307L9.28665 10.6397C8.94665 10.9797 8.48665 11.1731 7.99999 11.1731C7.51332 11.1731 7.05332 10.9864 6.71332 10.6397L2.36665 6.29307C2.27332 6.19307 2.21999 6.06641 2.21999 5.93974Z' fill='%235F6073'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right top 5px;
    border-bottom: 0.2px solid #c1c2d0;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 1.5px;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66668 14.4999C3.90001 14.4999 0.833344 11.4333 0.833344 7.66659C0.833344 3.89992 3.90001 0.833252 7.66668 0.833252C11.4333 0.833252 14.5 3.89992 14.5 7.66659C14.5 11.4333 11.4333 14.4999 7.66668 14.4999ZM7.66668 1.83325C4.44668 1.83325 1.83334 4.45325 1.83334 7.66659C1.83334 10.8799 4.44668 13.4999 7.66668 13.4999C10.8867 13.4999 13.5 10.8799 13.5 7.66659C13.5 4.45325 10.8867 1.83325 7.66668 1.83325Z' fill='%235F6073'/%3E%3Cpath d='M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z' fill='%235F6073'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: #edeeff;
      border-radius: 5px;
    }

    input {
      width: 230px;
      height: 24px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      background-color: #edeeff;
      border-radius: 5px;
      border: none;

      &::placeholder {
        color: #c1c2d0;
      }

      &:focus {
        outline: none;
      }
    }
  }

  ul {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    list-style: none;

    li {
      padding: 0;
      padding-left: 44px;
      height: 30px;
      padding-top: 1.5px;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      background-repeat: no-repeat;
      background-position: right 0 top 0;
      border-bottom: 0.2px solid #c1c2d0;
      cursor: pointer;

      &.active {
        background-image: url(../../../../assets/images/auth-form/selected.svg);
      }
    }
  }

  svg {
    position: absolute;
    right: 23px;
    top: -18px;
    filter: drop-shadow(-1px 5px 16px rgba(0, 0, 0, 0.13));
  }
}
