.AddWorkspace {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 121px);
  padding-top: 92px;
  background-image: url(../../assets/images/addWorkspaceBackground.svg);
  background-repeat: no-repeat;
  background-position: right bottom;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 452px;
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: white;
    box-shadow: -1px 5px 63px rgba(0, 0, 0, 0.13);
    border-radius: 30px;

    h1 {
      width: 352px;
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #1b1c37;
    }

    form {
      width: 352px;

      .inputContainer {
        margin-bottom: 25px;

        .error {
          padding-top: 5px;
          font-size: 12px;
          color: red;
        }
      }

      .submitButton {
        width: 100%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: white;
        background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
        border-radius: 10px;
        border: none;
      }
    }
  }
}
