.RightSidebar {
  position: relative;
  width: 298px;
  min-height: 100vh;
  padding-left: 37px;
  padding-right: 37px;
  background-color: #edeeff;
  transition: width 0.2s;

  &.collapsed {
    position: relative;
    width: 40px;
    padding-right: 0;
    padding-left: 0;
    box-shadow: -1px 5px 40px rgba(0, 0, 0, 0.13);

    .hideButton {
      transform: scaleX(-1);
      left: unset;
      right: -12px;

      svg {
        position: relative;
        left: 2px;
      }
    }
    header {
      display: none;
    }
    .users {
      display: none;
    }
  }

  .hideButton {
    position: absolute;
    top: 286px;
    left: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: #7579ff;
    border: 1.5px solid #edeeff;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 121px;
    border-bottom: 0.75px solid #c1c2d0;
    button {
      width: 50px;
      height: 50px;
      background-color: #7579ff;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }
  }

  .users {
    .user {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 0.75px solid #c1c2d0;

      .avatar {
        min-width: 40px;
        height: 40px;
        margin-right: 10px;
        background-color: #ffae80;
        border: 3px solid white;
        border-radius: 50%;
      }

      .userData {
        div {
          width: 100%;
          margin: 0;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
