* {
  box-sizing: border-box;
}

html {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}
