.backdrop {
  background-color: rgba(255, 255, 255, 0.7);
}

.dialog {
  max-width: 452px;
  box-sizing: border-box;
}

.CreateBriefModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  box-shadow: -1px 5px 63px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
  border: none;

  &.briefCreated {
    background-repeat: no-repeat;
    background-image: url(../../assets/images/add-brief/background1.svg),
      url(../../assets/images/add-brief/background2.svg),
      url(../../assets/images/add-brief/backgroundDot.svg),
      url(../../assets/images/add-brief/backgroundDot.svg),
      url(../../assets/images/add-brief/backgroundDot.svg);

    background-position: top 60px left 50px, top 50px right 50px,
      top 104px left 179px, top 166px left 131px, top 104px right 162px;
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 78px;
    padding-bottom: 50px;

    svg {
      margin-bottom: 10px;
    }

    h1 {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #5f6073;
    }

    .briefLink {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .close {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .content {
    padding-bottom: 100px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 352px;
      margin-bottom: 25px;
      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #1b1c37;
      }

      button {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: transparent;
        padding: 0;
        border: none;
      }
    }
  }

  form {
    .required {
      color: #7a74fb;
    }

    .error {
      padding-top: 5px;
      font-size: 12px;
      color: red;
    }

    .selectContainer,
    .inputContainer {
      margin-bottom: 25px;
    }

    .editorContainer {
      margin-bottom: 25px;
      .label {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1b1c37;
      }

      .error {
        padding-top: 0;
      }
    }

    .dropzoneContainer {
      .button {
        margin-top: 25px;
      }

      .files {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 352px;
        gap: 15px;
        font-size: 12px;

        .file {
          display: flex;
          align-items: center;
          button {
            position: relative;
            top: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            cursor: pointer;
          }
        }
      }

      .dropzoneLabel {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1b1c37;
      }

      .dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 192px;
        background-color: #edeeff;
        border: 4px dashed #7579ff;
        border-radius: 10px;
        cursor: pointer;

        .infoContainer {
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            height: 30px;
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .info {
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;

            span {
              color: #7a74fb;
            }
          }

          .supportedFiles {
            margin: 0;
            font-weight: 400;
            font-size: 8px;
            line-height: 12px;
            color: #c1c2d0;
          }
        }
      }
    }

    .button {
      width: 100%;
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: white;
      background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
      border-radius: 10px;
      border: none;
    }
  }
}
