.DownloadButton {
  position: relative;
  display: flex;
  align-items: center;
  width: 138px;
  height: 46px;
  margin-right: 14px;
  padding-left: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: white;
  background-color: #7579ff;
  border-radius: 12px;
  border: none;
  cursor: pointer;
}
