.AddOrEditTicket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  header {
    .closeButton {
      position: absolute;
      z-index: 3;
      top: 22px;
      right: 22px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7579ff;
      border-radius: 50%;
      border: none;
      cursor: pointer;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(90deg, #b224ef 0%, #7579ff 100%);
    opacity: 0.3;
  }
  .container {
    position: relative;
    z-index: 1;
    width: 1152px;
    padding-top: 82px;
    padding-bottom: 127px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: white;
    border-radius: 24px;

    form {
      display: flex;

      .success {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 24px;

        .successIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 83px;
          height: 82px;
          margin-bottom: 18px;
          background-color: rgba(117, 255, 131, 0.4);
          border: 4px solid #292d32;
          border-radius: 50%;
        }

        .congratulations {
          margin: 0;
          margin-bottom: 54px;
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
          text-align: center;
          color: #5f6073;
        }

        .cerrar {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
          user-select: none;
        }
      }

      .link {
        display: inline-block;
        width: 100%;
        font-size: 12px;
        text-align: right;
      }

      .error {
        padding-top: 5px;
        font-size: 12px;
        color: red;
      }

      .submitButton {
        width: 450px;
        height: 51px;
        font-weight: 600;
        font-size: 12.5217px;
        line-height: 19px;
        color: #edeeff;
        background-color: #1b1c37;
        cursor: pointer;
      }

      .editorContainer {
        width: 499px;
        .label {
          margin: 0;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
        }

        .files {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 15px;
          margin-top: 5px;
          font-size: 12px;

          .file {
            display: flex;
            align-items: center;
            button {
              position: relative;
              top: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              border: none;
              cursor: pointer;
            }
          }
        }
      }

      .selectContainer {
        margin-bottom: 66px;
      }

      .radiosContainer {
        margin-bottom: 51px;

        .radios {
          display: flex;
          gap: 15px;
          height: 40px;
        }
        .label {
          margin-top: 0;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
        }
        label {
          display: flex;
          align-items: center;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          color: #5f6073;

          input {
            margin-left: 5px;
          }
        }
      }

      .leftColumn {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 60px;
          right: -78px;
          display: block;
          height: 86%;
          width: 1px;
          background-color: #edeeff;
        }
      }

      .rightColumn {
        margin-left: 123px;
      }
      .inputContainer {
        margin-bottom: 51px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .AddOrEditTicket {
    min-height: auto;
    overflow-x: hidden;

    .container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: 5px;
      margin-right: 5px;
      form {
        display: block;

        .submitButton {
          width: 290px;
        }

        .editorContainer {
          width: 290px;
          margin-bottom: 51px;
        }

        .leftColumn {
          &::after {
            display: none;
          }
        }

        .rightColumn {
          margin-left: 0;
        }
      }
    }
  }
}
