.Editor {
  position: relative;
  min-height: 228px;
  margin-bottom: 5px;
  border-radius: 4px;

  .toolbarIcon {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .addAttachment {
    position: absolute;
    z-index: 1;
    bottom: 6px;
    right: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 19px;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    cursor: pointer;

    img {
      position: relative;
    }

    &:hover {
      box-shadow: 1px 1px 0px #bfbdbd;
    }

    &:active {
      box-shadow: 1px 1px 0px #bfbdbd inset;
    }
  }

  header {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 13px;
    padding-right: 30px;
    padding-bottom: 8px;
    background-color: #edeeff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .limit {
      font-style: italic;
      font-weight: 275;
      font-size: 10px;
      line-height: 15px;
      color: #5f6073;
      user-select: none;
    }
  }

  .placeholder {
    position: absolute;
    top: 36px;
    left: 30px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #5f6073;
    pointer-events: none;
    user-select: none;
  }

  .wrapper {
    background-color: #edeeff;
  }

  .toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    margin: 0;
    padding: 0;
    padding-top: 4px;
    padding-left: 25px;
    background-color: #f5f5ff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: none;
  }

  .editor {
    position: relative;
    top: -7px;
    max-width: 499px;
    min-height: 170px;
    max-height: 170px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1024px) {
  .Editor {
    max-width: 290px;

    .addAttachment {
      right: 115px;
    }

    .editor {
      max-width: 290px;
    }
  }
}
