.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 121px;
  padding-left: 50px;
  padding-right: 32px;
  box-shadow: 2px 4px 8px rgba(117, 121, 255, 0.18);

  .userBlock {
    .username {
      margin: 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;
      color: #1b1c37;
    }

    .date {
      margin: 0;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: #1b1c37;
    }
  }

  .searchbarContainer {
    input {
      width: 520px;
      height: 40px;
      padding-left: 62px;
      padding-right: 22px;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.635 17.8725L15.7637 13.9996C18.6604 10.1286 17.8706 4.64234 13.9996 1.74566C10.1286 -1.15102 4.64234 -0.361187 1.74566 3.50978C-1.15102 7.38076 -0.361187 12.8671 3.50979 15.7637C6.61927 18.0906 10.8901 18.0906 13.9996 15.7637L17.8725 19.6366C18.3592 20.1233 19.1483 20.1233 19.635 19.6366C20.1217 19.1499 20.1217 18.3609 19.635 17.8742L19.635 17.8725ZM8.78697 15.0162C5.34663 15.0162 2.55772 12.2273 2.55772 8.78697C2.55772 5.34663 5.34663 2.55772 8.78697 2.55772C12.2273 2.55772 15.0162 5.34663 15.0162 8.78697C15.0126 12.2257 12.2258 15.0126 8.78697 15.0162Z' fill='%231B1C37'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 20px center;
      border: 2px solid #1b1c37;
      border-radius: 8px;
      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #1b1c37;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    .addButton,
    .workspaceButton {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      height: 50px;
      background-color: #7579ff;
      border: 0.25px solid #ffffff;
      backdrop-filter: blur(4px);
      border-radius: 12px;
      cursor: pointer;
    }

    .backButton {
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: 3px solid #7579ff;
      border-radius: 13px;
    }

    .workspaceButton {
      margin-right: 15px;
    }
  }

  .workspaceMenuContainer {
    position: absolute;
    top: 106px;
    right: 94px;
    z-index: 100;
  }

  .addEntityMenu {
    position: absolute;
    z-index: 100;
    top: 106px;
    right: 30px;
    width: 214px;
    height: 100px;
    padding-top: 16.5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    background-color: white;
    border-radius: 11px;
    box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      display: block;
      width: 214px;
      height: 100px;
      background-color: white;
      border-radius: 11px;
    }

    ul {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0;
      list-style: none;

      li {
        padding: 0;
        padding-left: 34px;
        height: 30px;
        padding-top: 1.5px;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        background-repeat: no-repeat;
        background-position: left 0 top 0;
        border-bottom: 0.2px solid #c1c2d0;
        cursor: pointer;

        &.briefs {
          background-image: url(../../../assets/images/header/briefs.svg);
        }

        &.workspaces {
          background-image: url(../../../assets/images/header/workspaces.svg);
        }
      }
    }

    svg {
      position: absolute;
      right: 23px;
      top: -18px;
      filter: drop-shadow(-1px 5px 16px rgba(0, 0, 0, 0.13));
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    padding-left: 20px;
    padding-right: 20px;

    .burger {
      min-width: 50px;
      height: 50px;
      background-image: url(../../../assets/images/sidebar/burger.svg);
      background-color: #7579ff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 36px;
      border-radius: 12px;
    }

    .userBlock {
      display: none;
    }

    .searchbarContainer {
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
      input {
        width: 100%;
      }
    }
  }
}
