.DraftEditor-root {
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
}

.rdw-link-modal {
  top: 30px;
  height: 250px;
}
