.toggle {
  position: relative;
  right: -8px;
  width: 16px;
  height: 16px;
  background-image: url(../../../../assets/images/card/dots.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.menu {
  min-width: 100px;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  font-size: 12px;

  ul {
    margin: 0;
  }
}
