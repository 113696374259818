.Image {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  .selectionContainer {
    position: absolute;
    // pointer-events: none;
  }
}

.selection {
  position: absolute;
  border: 4px dashed #edeeff;
  border-radius: 24px;
}
