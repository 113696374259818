.VideoTag {
  position: absolute;
  z-index: 1111111;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-height: 20px;
  background-image: url(../../../assets/images/ticket/tagBackground.svg);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  transform: matrix(1, 0, 0, -1, 0, 0);
  cursor: pointer;

  .tooltip {
    position: absolute;
    top: calc(100% + 10px);
    display: flex;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 7px;
    line-height: 10px;
    color: white;
    background: #1b1c37;
    border-radius: 5px;

    span {
      display: inline-block;
      min-width: 75px;
      // max-width: 157px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: calc(100% - 4px);
      display: block;
      width: 13px;
      height: 12px;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.23205 11C7.46225 12.3333 5.53775 12.3333 4.76795 11L0.43782 3.5C-0.33198 2.16667 0.630272 0.500001 2.16987 0.500001L10.8301 0.5C12.3697 0.5 13.332 2.16667 12.5622 3.5L8.23205 11Z' fill='%231B1C37'/%3E%3C/svg%3E%0A");
      transform: rotate(180deg);
    }
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    font-weight: 700;
    font-size: 10.3226px;
    line-height: 15px;
    color: #7579ff;
    border-radius: 50%;
    user-select: none;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}
