.MediaSlider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 42px;
  padding-top: 50px;
  padding-bottom: 50px;

  .prevMedia {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 200px;
    padding-left: 45px;
    padding-right: 45px;
    top: 0;
    filter: blur(6px);
    transition: top 0.12s;

    &.isSwitching {
      top: 50px;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      object-position: top;
      border-radius: 31px;
      overflow: hidden;
    }
  }

  .nextMedia {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 200px;
    padding-left: 45px;
    padding-right: 45px;
    bottom: 0;
    filter: blur(6px);
    transition: bottom 0.12s;

    &.isSwitching {
      bottom: 50px;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      object-position: bottom;
      border-radius: 31px;
    }
  }

  .mediaContent {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    aspect-ratio: 827 / 469;
    border-radius: 31px;
    background-color: black;
    overflow: hidden;
    cursor: pointer;

    img,
    video {
      position: relative;
      z-index: 1;
      max-width: 100%;
      height: 100%;
      background-color: white;
      object-fit: cover;
      opacity: 1;

      &.fadeOut {
        opacity: 0;
        transition: opacity 0.3s;
      }

      &.nextImage {
        position: absolute;
        z-index: 0;
      }
    }
  }

  .scrollbar {
    position: absolute;
    z-index: 121;
    top: 0;
    right: -50px;
    height: 100%;
    width: 10px;
    background-color: #e5e6ea;
    border-radius: 5px;

    .thumb {
      position: absolute;
      top: 0;
      width: 100%;
      height: 180px;
      background: linear-gradient(#b224ef, #7579ff);
      border-radius: 5px;
      transition: all 0.2s;

      &.isDragging {
        transition: none;
      }
    }
  }
}
