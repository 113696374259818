.Select {
  position: relative;
  width: 100%;
  .label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    .required {
      color: #7a74fb;
    }
  }

  .selector {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-left: 64px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #5f6073;
    background-image: url(../../../assets/images/auth-form/position.svg);
    background-repeat: no-repeat;
    background-position: 20px center;
    border: 1px solid #1b1c37;
    border-radius: 10px;

    &.none {
      padding-left: 20px;
      background-image: none;
    }

    &.open {
      border-radius: 10px 10px 0 0;
      border-bottom: none;

      &::after {
        transform: rotate(90deg);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 16px;
      right: 20px;
      display: block;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.93974 13.78C5.81307 13.78 5.68641 13.7333 5.58641 13.6333C5.39307 13.44 5.39307 13.12 5.58641 12.9267L9.93307 8.58001C10.2531 8.26001 10.2531 7.74001 9.93307 7.42001L5.58641 3.07335C5.39307 2.88001 5.39307 2.56001 5.58641 2.36668C5.77974 2.17335 6.09974 2.17335 6.29307 2.36668L10.6397 6.71335C10.9797 7.05335 11.1731 7.51335 11.1731 8.00001C11.1731 8.48668 10.9864 8.94668 10.6397 9.28668L6.29307 13.6333C6.19307 13.7267 6.06641 13.78 5.93974 13.78Z' fill='%235F6073'/%3E%3C/svg%3E%0A");
      transition: transform 0.1s;
    }
  }

  .options {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 19px;
    background-color: white;
    border: 1px solid #1b1c37;
    border-radius: 0 0 10px 10px;
    border-top: none;
    overflow: hidden;

    &.none {
      .option {
        padding-left: 0;
      }
    }

    .option {
      position: relative;
      padding-top: 10px;
      padding-bottom: 7.5px;
      padding-left: 44px;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      border-bottom: 0.2px solid #c1c2d0;
      cursor: pointer;

      &:first-child {
        border-top: 0.2px solid #c1c2d0;
      }

      &.active {
        background-image: url(../../../assets/images/auth-form/selected.svg);
        background-repeat: no-repeat;
        background-position: center right;
      }

      &.brand {
        &::after {
          background-image: url(../../../assets/images/auth-form/brand.svg);
        }
      }

      &.creativityAgency {
        &::after {
          background-image: url(../../../assets/images/auth-form/agency.svg);
        }
      }

      &.founder {
        &::after {
          background-image: url(../../../assets/images/auth-form/founder.svg);
        }
      }

      &.marketer {
        &::after {
          background-image: url(../../../assets/images/auth-form/brand.svg);
        }
      }

      &.designer {
        &::after {
          background-image: url(../../../assets/images/auth-form/designer.svg);
        }
      }

      &.legal {
        &::after {
          background-image: url(../../../assets/images/auth-form/legal.svg);
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Select {
    .label {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 13.5929px;
      line-height: 20px;
    }

    .selector {
      height: 40px;
      padding-left: 54px;
      font-weight: 300;
      font-size: 13.5929px;
      line-height: 20px;
      background-size: 20px 20px;
      background-position: 17px;

      &::after {
        width: 14px;
        height: 14px;
        background-size: contain;
        top: calc(50% - 7px);
      }
    }

    .options {
      padding-left: 17px;
      padding-right: 17px;
      .option {
        font-size: 11.8938px;
        line-height: 18px;

        &.active {
          background-size: 20px;
        }

        &::after {
          width: 20px;
          height: 20px;
          background-size: contain;
        }
      }
    }
  }
}
