.GridView {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 58px;

  .file {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - 7px);
    aspect-ratio: (284 / 182);
    box-shadow: (-1px 5px 16px rgba(0, 0, 0, 0.13));
    border-radius: 6px;
    overflow: hidden;

    .media {
      position: relative;
      flex: 1;
      overflow: hidden;
      cursor: pointer;

      .checkbox {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='10' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");

        &.active {
          background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='10' stroke='white' stroke-width='2'/%3E%3Ccircle cx='11' cy='11' r='6' fill='white'/%3E%3C/svg%3E%0A");
        }
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    footer {
      min-height: 38px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
