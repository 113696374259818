.EmailMultiInput {
  background-image: url(../../../assets/images/auth-form/name.svg);
  background-repeat: no-repeat;
  background-position: top 48px left 20px;

  label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1b1c37;

    .required {
      color: #7a74fb;
    }
  }

  input {
    width: 100%;
    height: 48px;
    padding-left: 64px;
    padding-right: 20px;
    font-weight: 300;
    font-size: 13.5929px;
    line-height: 20px;
    background-color: transparent;
    border: 1px solid #1b1c37;
    border-radius: 10px;

    &.emailsNotEmpty {
      border-bottom: none;
      border-radius: 10px 10px 0 0;
    }

    &::placeholder {
      color: #5f6073;
    }

    &:focus {
      outline: none;
    }
  }

  .emails {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 22px;
    border: 1px solid #1b1c37;
    border-top: none;
    border-radius: 0 0 10px 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-top: 0.2px solid #c1c2d0;

      li {
        padding-top: 11.5px;
        padding-bottom: 6px;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        border-bottom: 0.2px solid #c1c2d0;

        &.active {
          background-image: url(../../../assets/images/auth-form/selected.svg);
          background-repeat: no-repeat;
          background-position: top 10px right;
        }
      }
    }
  }
}
